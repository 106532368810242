import { Component, createApp } from 'vue'
import { Router } from 'vue-router'
import { VueQueryPlugin } from 'vue-query'
import { globalProperties } from './globalProperties'
import { pinia } from '@/stores'
import { setHTTPHeader } from '@/services/http.service'
import AuthService from '@/services/auth.service'
import vuetify from '@/plugins/vuetify'


// CJS - commented all of this out in an experiment to remove Avast "Web Threat" - HTML:SCRIPT-inf error (see email from Jon 8/23/24 @ 7:30am)
//const token = AuthService.getToken()  // CJS - This will always return false because the token is never set (also attempts to retrieve from localStorage vs. sessionStorage)

//if (token) {
//  setHTTPHeader({ Authorization: token })
//  // CJS - Experiment (HTTP Token)
//  console.log ("setHHPEHeader using sessionStorage in auth.service.ts")
//}

export const setupEntryPoint = (rootComponent: Component, router: Router) => {
  const app = createApp(rootComponent)

  app.use(router)
  app.use(pinia)
  app.use(VueQueryPlugin)
  app.use(vuetify)
  app.config.globalProperties = globalProperties

  app.mount('#app')
}
